import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { jwtDecode } from "jwt-decode"; // Updated import
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";

export const registerUser = (userData, history) => dispatch => {
    axios
        .post("/api/register", userData)
        .then(res => history.push("/login"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response?.data || 'An error occurred'
            })
        );
};

export const loginUser = userData => dispatch => {
    axios
        .post("/api/login", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwtDecode(token); // Updated usage
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response?.data || 'An error occurred'
            })
        );
};

export const setCurrentUser = decoded => ({
    type: SET_CURRENT_USER,
    payload: decoded
});

export const setUserLoading = () => ({
    type: USER_LOADING
});

export const logoutUser = () => dispatch => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};