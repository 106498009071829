import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import $ from 'jquery';

// jQuery setup
window.jQuery = $;
window.$ = $;

// Create root
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// jQuery event handlers
$(document).ready(() => {
  $("#menu-toggle").on('click', () => {
    $("#wrapper").toggleClass("toggled");
  });

  $('.modal[data-reset="true"]').on('shown.bs.modal', () =>
    $("input[name != 'timestamp']").val(''));

  $('.modal').on('shown.bs.modal', () =>
    $('input[data-reset-input="true"]').val(''));
});