import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

const Login = ({ loginUser, auth, errors: errorsProp }) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Redirect if logged in
        if (auth.isAuthenticated) {
            const from = location.state?.from || "../dashboard";
            navigate(from, { replace: true });
        }
    }, [auth.isAuthenticated, navigate, location]);

    useEffect(() => {
        // Update errors from props
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    const onChange = e => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const onSubmit = e => {
        e.preventDefault();
        loginUser(formData);
    };

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                    <div className="card-body p-1">
                        <h2 className="text-center text-primary mt-3">Type Apps Login</h2>
                        {/* Show message from PrivateRoute if it exists */}
                        {location.state?.message && (
                            <div className="alert alert-info">
                                {location.state.message}
                            </div>
                        )}
                        <form noValidate onSubmit={onSubmit} className="white">
                            <label htmlFor="email">Email</label>
                            <input
                                onChange={onChange}
                                value={formData.email}
                                error={errors.email}
                                id="email"
                                type="email"
                                className={classnames("form-control", {
                                    invalid: errors.email
                                })}
                            />
                            <span className="text-danger">{errors.email}</span>
                            <br/>
                            <label htmlFor="password">Password</label>
                            <input
                                onChange={onChange}
                                value={formData.password}
                                error={errors.password}
                                id="password"
                                type="password"
                                className={classnames("form-control", {
                                    invalid: errors.password
                                })}
                            />
                            <span className="text-danger">{errors.password}</span>
                            <p className="text-center pb-0 mt-2">
                                <button
                                    type="submit"
                                    className="btn btn-large btn-primary mt-2 px-5">
                                    Login
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { loginUser }
)(Login);