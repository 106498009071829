import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import React from 'react';
import Login from './components/auth/Login';
import NotFound from './components/layout/NotFound';
import { Provider } from 'react-redux';
import PrivateRoute from './components/private-route/PrivateRoute';
import Register from './components/auth/Register';
import store from './store';
import { jwtDecode } from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import PropTypes from 'prop-types'; // Import PropTypes
import Cursor from './components/Cursor';

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'jquery/dist/jquery.min.js';

// Component imports
import User from './components/pages/Users';
import Cms from './components/pages/Cms';
import Home from './containers/Home';
import Contact from './containers/Contact';
import Blog from './containers/Blog';
import Detail from './containers/Detail';
import HireDeveloper from './containers/Cms/HireDeveloper';
import Company from './containers/Cms/Company';
import OurProcess from './containers/Cms/OurProcess';
import BookCall from './containers/Cms/BookCall';
import HowItWork from './containers/Cms/HowItWork';
import Howtohire from './containers/Cms/Howtohire';
import Services from './containers/Services';
import Blogs from './components/pages/Blog';
import Portfolio from './components/pages/Portfolio';
import ReactGA from 'react-ga';
const TRACKING_ID = 'G-D6WM8E7KN0';
ReactGA.initialize(TRACKING_ID);

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = './login';
  }
}

// Layout wrapper for protected routes
const ProtectedLayout = ({ children }) => {
  return <PrivateRoute>{children}</PrivateRoute>;
};

// Add PropTypes validation
ProtectedLayout.propTypes = {
  children: PropTypes.node.isRequired
};

// Define router configuration
const router = createBrowserRouter([
  {
    path: '/',
    children: [
      { index: true, element: <Home /> },
      { path: 'portfolio', element: <Portfolio /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: 'contact', element: <Contact /> },
      { path: 'blog', element: <Blog /> },
      { path: 'detail', element: <Detail /> },
      { path: 'hiredeveloper', element: <HireDeveloper /> },
      { path: 'bookcall', element: <BookCall /> },
      { path: 'company', element: <Company /> },
      { path: 'howitwork', element: <HowItWork /> },
      { path: 'services', element: <Services /> },
      { path: 'howtohire', element: <Howtohire /> },
      
      // Protected routes
      {
        path: 'dashboard',
        element: <ProtectedLayout><Dashboard /></ProtectedLayout>,
      },
      {
        path: 'users',
        element: <ProtectedLayout><User /></ProtectedLayout>,
      },
      {
        path: 'cms',
        element: <ProtectedLayout><Cms /></ProtectedLayout>,
      },
      {
        path: 'blogs',
        element: <ProtectedLayout><Blogs /></ProtectedLayout>,
      },
      { path: '*', element: <NotFound /> }
    ]
  }
]);

const App = () => {
  return (
    <Provider store={store}>
      <Cursor />
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;