import React from "react";
import { FlashCircle } from "../../assets/icons/FlashCircle";
import { ChevronDownR } from "../../assets/icons/ChevronDownR";
import { FlipBackwardWrapper } from "../../assets/icons/FlipBackwardWrapper";
import "./portfolioDesktop.css";

import sleekride from "../../assets/images/portfolio/sleekride.png";
import bred from "../../assets/images/portfolio/bred.png";
import leeosMerch from "../../assets/images/portfolio/leeos-merch.png";
import stayFresh from "../../assets/images/portfolio/stay-fresh.png";
import insureCert from "../../assets/images/portfolio/insurecert.png";

const PortfolioDesktop = () => {
    return (
        <div className="portfolio-container">
            <div className="portfolio-content">
                <div className="header-section">
                    <div className="title-group">
                        <div className="badge">
                            <FlashCircle className="flash-icon" />
                            <span className="badge-text">Our Work</span>
                        </div>
                        <h1 className="main-title">Our Projects</h1>
                    </div>
                    <div className="divider" />
                    {/* <div className="filter-section">
                        <div className="filter-group">
                            <div className="filter-item">
                                <span>Categories</span>
                                <ChevronDownR className="chevron-icon" />
                            </div>
                            <div className="filter-item">
                                <span>Technology</span>
                                <ChevronDownR className="chevron-icon" />
                            </div>
                            <div className="filter-item">
                                <span>Sort By</span>
                                <ChevronDownR className="chevron-icon" />
                            </div>
                        </div>
                        <div className="reset-filter">
                            <span>Reset Filter</span>
                            <FlipBackwardWrapper className="reset-icon" />
                        </div>
                    </div> */}
                </div>

                <div className="projects-grid">
                    <div className="project-row">
                        <div className="project-card full-width">
                            <img src={sleekride} alt="Sleekride" className="project-image" />
                            <div className="project-info">
                                <div className="project-details">
                                    <h2>Sleekride</h2>
                                    <p>Sleekride is an online car trading platform with seller insights and analytics.</p>
                                </div>
                                <div className="project-tags">
                                    <span className="tag">Website</span>
                                    <span className="tag">Mobile App</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="project-row">
                        <div className="project-card">
                            <img src={bred} alt="Bred" className="project-image" />
                            <div className="project-info">
                                <h2>BRED.</h2>
                                <p>A live stock-marketplace for athletic luxury footwear with analytical tools for Buyers and Sellers</p>
                                <div className="project-tags">
                                    <span className="tag">Mobile App</span>
                                </div>
                            </div>
                        </div>
                        <div className="project-card">
                            <img src={leeosMerch} alt="Leeos Merch" className="project-image" />
                            <div className="project-info">
                                <h2>Leeos Merch</h2>
                                <p>Comprehensive merchandising solutions for all your needs</p>
                                <div className="project-tags">
                                    <span className="tag">Website</span>
                                    <span className="tag">E-commerce</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="project-row">
                        <div className="project-card">
                            <img src={stayFresh} alt="Stay Fresh" className="project-image" />
                            <div className="project-info">
                                <h2>Stay Fresh</h2>
                                <p>Your go-to for premium sneakers, streetwear, and collectibles.</p>
                                <div className="project-tags">
                                    <span className="tag">Website</span>
                                    <span className="tag">E-commerce</span>
                                </div>
                            </div>
                        </div>
                        <div className="project-card">
                            <img src={insureCert} alt="InsureCert" className="project-image" />
                            <div className="project-info">
                                <h2>InsureCert</h2>
                                <p>InsureCert provides a 360° view of insurance, seamlessly integrating underwriting, marketing, payments, and renewals through secure, custom portals.</p>
                                <div className="project-tags">
                                    <span className="tag">Website</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioDesktop;