import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCms } from "../../actions/cmsActions";
import { toast } from 'react-toastify';
import $ from 'jquery';

const pageList = { 
    "home": "home",
    "services": "services",
    "why-type": "why-type",
    "blog": "blog",
    "talent": "talent",
    "contact": "contact" 
};

const CmsUpdateModal = ({ updateCms, auth, errors: errorsProp, record }) => {
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        id: record?.id || '',
        title: record?.title || '',
        content: record?.content || '',
        status: record?.status || '',
        alias: record?.alias || '',
        meta_title: record?.meta_title || '',
        meta_description: record?.meta_description || '',
        meta_keyword: record?.meta_keyword || '',
        page_link: pageList[record?.alias] || ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (record) {
            setFormData({
                id: record.id,
                title: record.title,
                alias: record.alias,
                content: record.content,
                status: record.status,
                meta_title: record.meta_title,
                meta_description: record.meta_description,
                meta_keyword: record.meta_keyword,
                page_link: pageList[record.alias]
            });
        }
    }, [record]);

    useEffect(() => {
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    useEffect(() => {
        if (auth?.user?.data?.message && auth?.user?.data?.success) {
            $('#update-page-modal').modal('hide');
            toast(auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [auth?.user?.data]);

    const handleChange = e => {
        setFormData(prev => ({
            ...prev,
            content: e.target.value
        }));
    };

    const onChange = e => {
        const fieldId = e.target.id.replace('page-update-', '');
        setFormData(prev => ({
            ...prev,
            [fieldId]: e.target.value
        }));
    };

    const onPageUpdate = e => {
        e.preventDefault();
        const newPage = {
            _id: formData.id,
            title: formData.title,
            content: formData.content,
            status: formData.status,
            alias: formData.alias,
            meta_title: formData.meta_title,
            meta_description: formData.meta_description,
            meta_keyword: formData.meta_keyword
        };
        updateCms(newPage, navigate);
    };

    return (
        <div>
            <div className="modal fade" id="update-page-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Page</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={onPageUpdate} id="update-page">
                                <input
                                    onChange={onChange}
                                    value={formData.id}
                                    id="page-update-id"
                                    type="text"
                                    className="d-none" 
                                />
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-title">Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input 
                                            onChange={onChange}
                                            value={formData.title}
                                            id="page-update-title"
                                            type="text"
                                            error={errors.title}
                                            className={classnames("form-control", {
                                                invalid: errors.title
                                            })} 
                                        />
                                        <span className="text-danger">{errors.title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-alias">Alias</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.alias}
                                            error={errors.alias}
                                            id="page-update-alias"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.alias
                                            })}
                                        />
                                        <span className="text-danger">{errors.alias}</span>
                                        {formData.page_link && (
                                            <Link target="_blank" to={formData.page_link}>
                                                Page
                                            </Link>
                                        )}
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-content">Content</label>
                                    </div>
                                    <div className="col-md-9">
                                        <textarea 
                                            value={formData.content} 
                                            id="page-update-content" 
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                        <span className="text-danger">{errors.content}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-status">Status</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select 
                                            value={formData.status} 
                                            id="page-update-status" 
                                            onChange={onChange}
                                            className="form-control"
                                        >
                                            <option value="1">Enable</option>
                                            <option value="0">Disable</option>
                                        </select>
                                        <span className="text-danger">{errors.status}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-meta_title">Meta Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input 
                                            onChange={onChange}
                                            value={formData.meta_title}
                                            error={errors.meta_title}
                                            id="page-update-meta_title"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_title
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-meta_description">Meta Description</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input 
                                            onChange={onChange}
                                            value={formData.meta_description}
                                            id="page-update-meta_description"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_description
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_description}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="page-update-meta_keyword">Meta Keyword</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input 
                                            onChange={onChange}
                                            value={formData.meta_keyword}
                                            id="page-update-meta_keyword"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_keyword
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_keyword}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                Close
                            </button>
                            <button
                                form="update-page"
                                type="submit"
                                className="btn btn-primary"
                            >
                                Update Page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CmsUpdateModal.propTypes = {
    updateCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateCms }
)(CmsUpdateModal);