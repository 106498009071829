import React, { useEffect } from 'react';

const Cursor = () => {
  useEffect(() => {
    // Remove any existing cursor elements first
    const existingDots = document.querySelectorAll('.cursor-dot');
    const existingOutlines = document.querySelectorAll('.cursor-dot-outline');
    
    existingDots.forEach(dot => dot.remove());
    existingOutlines.forEach(outline => outline.remove());
    
    // Create cursor elements
    const cursorDot = document.createElement('div');
    cursorDot.className = 'cursor-dot';
    
    const cursorOutline = document.createElement('div');
    cursorOutline.className = 'cursor-dot-outline';
    
    // Add cursor elements to the DOM
    document.body.appendChild(cursorDot);
    document.body.appendChild(cursorOutline);
    
    // Move cursor with mouse
    const updateCursorPosition = (e) => {
      const posX = e.clientX;
      const posY = e.clientY;

      requestAnimationFrame(() => {
        cursorDot.style.left = `${posX}px`;
        cursorDot.style.top = `${posY}px`;
        
        setTimeout(() => {
          cursorOutline.style.left = `${posX}px`;
          cursorOutline.style.top = `${posY}px`;
        }, 50);
      });
    };
    
    window.addEventListener("mousemove", updateCursorPosition);
    
    // Add hover effect for interactive elements
    const addInteractiveEffects = () => {
      const interactiveElements = document.querySelectorAll(
        'a, button, .btn, .nav-link, [role="button"], input[type="button"], input[type="submit"], .navbar-brand, .nav-item, .service-card, .checkBox label, .swiper-button-next, .swiper-button-prev, [onclick], .clickable'
      );
      
      interactiveElements.forEach(el => {
        el.addEventListener('mouseenter', () => {
          cursorDot.style.transform = 'translate(-50%, -50%) scale(1.5)';
          cursorDot.style.backgroundColor = 'rgba(35, 78, 250, 0.5)';
          
          cursorOutline.style.transform = 'translate(-50%, -50%) scale(1.5)';
          cursorOutline.style.width = '30px';
          cursorOutline.style.height = '30px';
          cursorOutline.style.borderColor = 'rgba(35, 78, 250, 0.5)';
        });
        
        el.addEventListener('mouseleave', () => {
          cursorDot.style.transform = 'translate(-50%, -50%)';
          cursorDot.style.backgroundColor = '#234efa';
          
          cursorOutline.style.transform = 'translate(-50%, -50%)';
          cursorOutline.style.width = '20px';
          cursorOutline.style.height = '20px';
          cursorOutline.style.borderColor = '#234efa';
        });
      });
    };
    
    // Initial setup
    addInteractiveEffects();
    
    // Re-apply effects when DOM changes
    const observer = new MutationObserver(addInteractiveEffects);
    observer.observe(document.body, { childList: true, subtree: true });
    
    // Force all elements to have cursor: none
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      * {
        cursor: none !important;
      }
    `;
    document.head.appendChild(styleTag);
    
    // Cleanup
    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
      observer.disconnect();
      if (document.body.contains(cursorDot)) document.body.removeChild(cursorDot);
      if (document.body.contains(cursorOutline)) document.body.removeChild(cursorOutline);
      if (document.head.contains(styleTag)) document.head.removeChild(styleTag);
    };
  }, []);
  
  return null;
};

export default Cursor;