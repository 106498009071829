import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './blog.css';

const Post = (props) => {
    const { blogData } = props;
    // console.log('blogData',blogData);
    return (
        <>
        { blogData.map((blog, index) => (
        <div key={index} className="bigBlog">
            <div className="blogImg">
                <Link to={`/detail?id=${blog._id}`} >
                    <img src={ '/blog_image/'+blog.image } alt={blog.title || "Blog post"} />
                </Link>
            </div>
            <div className="blogContent">
                <div className="metaData"><span className="tag">{ blog.categoryname }</span> <span className="date">Feb 04</span></div>
                <Link to={`/detail?id=${blog._id}`}><h3>{ blog.title }</h3></Link>
                <p className='elip' dangerouslySetInnerHTML={{__html:blog.description}}></p>
            </div>
        </div>
        ))}
        </>
    );
};

// Add PropTypes validation
Post.propTypes = {
    blogData: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            image: PropTypes.string,
            categoryname: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired
};

export default Post;