import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBlog } from "../../actions/blogActions";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const BlogUpdateModal = ({ updateBlog, auth, errors: errorsProp, record }) => {
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        id: record?.id || '',
        title: record?.title || '',
        category: record?.category || '',
        description: record?.description || '',
        image: record?.image || '',
    });

    const [errors, setErrors] = useState({});
    const [editorState, setEditorState] = useState(() => {
        if (record?.description) {
            const contentBlock = htmlToDraft(record.description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    });

    useEffect(() => {
        if (record) {
            const contentBlock = htmlToDraft(record.description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const newEditorState = EditorState.createWithContent(contentState);
            
            setEditorState(newEditorState);
            setFormData({
                id: record._id,
                title: record.title,
                category: record.category,
                description: record.description,
                image: record.image,
            });
        }
    }, [record]);

    useEffect(() => {
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    useEffect(() => {
        if (auth?.blog?.data?.message && auth?.blog?.data?.success) {
            $('#update-blog-modal').modal('hide');
            toast(auth.blog.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [auth?.blog?.data]);

    const onChange = e => {
        if (e.target.id === 'blog-update-image') {
            setFormData(prev => ({
                ...prev,
                image: e.target.files[0]
            }));
        } else {
            const fieldId = e.target.id.replace('blog-update-', '');
            setFormData(prev => ({
                ...prev,
                [fieldId]: e.target.value
            }));
        }
    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentRaw = convertToRaw(newEditorState.getCurrentContent());
        const contentHTML = draftToHtml(contentRaw);
        setFormData(prev => ({
            ...prev,
            description: contentHTML
        }));
    };

    const onBlogUpdate = e => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append("id", formData.id);
        formDataToSend.append("title", formData.title);
        formDataToSend.append("category", formData.category);
        formDataToSend.append("description", formData.description);
        if (formData.image instanceof File) {
            formDataToSend.append("image", formData.image);
        }
        
        updateBlog(formDataToSend, navigate);
    };

    return (
        <div>
            <div className="modal fade" id="update-blog-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Blog</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={onBlogUpdate} id="update-blog">
                                <input
                                    onChange={onChange}
                                    value={formData.id}
                                    id="blog-update-id"
                                    type="text"
                                    className="d-none"/>
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        {formData.id && 
                                            <Link style={{ float: "right" }} target="_blank" to={`detail?id=${formData.id}`}>
                                                Preview Page
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="blog-update-title">Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.title}
                                            id="blog-update-title"
                                            type="text"
                                            error={errors.title}
                                            className={classnames("form-control", {
                                                invalid: errors.title
                                            })}/>
                                        <span className="text-danger">{errors.title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="blog-update-category">Category</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select 
                                            value={formData.category}
                                            id="blog-update-category" 
                                            onChange={onChange}
                                            className={classnames("form-control", {
                                                invalid: errors.category
                                            })}
                                            error={errors.category}
                                        >
                                            <option value="62944f2e04a3eb3bbc3876ca">Career</option>
                                            <option value="62944f2e04a3eb3bbc3876cb">Knowledge</option>
                                            <option value="62944f2e04a3eb3bbc3876cc">Technology</option>
                                        </select>
                                        <span className="text-danger">{errors.category}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="description">Description</label>
                                    </div>
                                    <div className="col-md-9">
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                        <span className="text-danger">{errors.description}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="blog-update-image">Image</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            error={errors.image}
                                            id="blog-update-image"
                                            type="file"
                                            className={classnames("form-control", {
                                                invalid: errors.image
                                            })}
                                        />
                                        <span className="text-danger">{errors.image}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-blog"
                                type="submit"
                                className="btn btn-primary">
                                Update Blog
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlogUpdateModal.propTypes = {
    updateBlog: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateBlog }
)(BlogUpdateModal);