import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FlashCircle } from '../../assets/icons/FlashCircle';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import './services.css';

const Services = () => {
  // Set first item (index 0) as active by default
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);

  // Initialize with first item expanded
  useEffect(() => {
    setActiveIndex(0);
  }, []);

  // Determine which index to use for display (hover takes precedence over active)
  const displayIndex = hoverIndex !== null ? hoverIndex : activeIndex;

  const services = [
    {
      title: 'Custom Web & Mobile App Development',
      description: 'Responsive web applications and mobile apps for iOS and Android, including publishing to Apple and Google Play app stores.'
    },
    {
      title: 'E-commerce Solutions',
      description: 'Robust e-commerce platforms using Shopify, WooCommerce, tailored for your business. Building and customizing CMS solutions like WordPress for content flexibility.'
    },
    {
      title: 'API Development & Integration',
      description: 'Custom API development and integration with popular APIs as per business needs.'
    },
    {
      title: 'Cloud Services Development',
      description: 'Expert cloud solutions for app deployment, ensuring scalability and security.'
    },
    {
      title: 'Digital Marketing (SEO)',
      description: 'Search Engine Optimization (SEO) services to improve your website\'s visibility and ranking on Google and other search engines.'
    }
  ];

  return (
    <>
      <Header />
      <section className="services-section">
        <div className="service-header">
          <div className="service-tag">
            <FlashCircle />
            <span>Our Services</span>
          </div>
          <h1>Our Specialized Web Development Services</h1>
          <p>From initial consultation to final deployment, we offer a complete suite of web development services tailored to your needs.</p>
        </div>

        <div className="service-list">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className={`service-card ${displayIndex === index ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              initial={false}
              animate={{ 
                backgroundColor: displayIndex === index ? '#234BFA' : '#FFFFFF',
              }}
              transition={{ duration: 0.3 }}
            >
              <div className="service-card-header">
                <h3>{service.title}</h3>
              </div>
              <AnimatePresence>
                {displayIndex === index && (
                  <motion.div
                    className="service-content"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p>{service.description}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Services;