import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import axios from "axios";

function HowItWork() {
    const [pageDetails, setPageDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const alias = { alias: 'howitwork' };

    function getPage() {
        setIsLoading(true);
        axios.post("/api/page-view", alias)
            .then(response => response.data)
            .then((data) => {
                setPageDetails(data);
                setIsLoading(false);
            });
    }
    
    // Include getPage in the dependency array
    useEffect(() => {
        getPage();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>{pageDetails.meta_title}</title>
                <meta name="description" content={pageDetails.meta_description} />
                <meta name="keywords" content={pageDetails.meta_keyword} />
            </Helmet>
            <Header />
            <section className="heroSec">
                <h1>{pageDetails.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: pageDetails.content }}></div>
            </section>
            <Footer />
        </>
    );
}

export default HowItWork;