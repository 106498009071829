import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

const UserUpdateModal = ({ updateUser, auth, errors: errorsProp, record }) => {
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        id: record?.id || '',
        name: record?.name || '',
        email: record?.email || '',
        password: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (record) {
            setFormData({
                id: record.id,
                name: record.name,
                email: record.email,
                password: ''
            });
        }
    }, [record]);

    useEffect(() => {
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    useEffect(() => {
        if (auth?.user?.data?.message && auth?.user?.data?.success) {
            $('#update-user-modal').modal('hide');
            toast(auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [auth?.user?.data]);

    const onChange = e => {
        const fieldId = e.target.id.replace('user-update-', '');
        setFormData(prev => ({
            ...prev,
            [fieldId]: e.target.value
        }));
    };

    const onUserUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: formData.id,
            name: formData.name,
            email: formData.email,
            password: formData.password
        };
        updateUser(newUser, navigate);
    };

    return (
        <div>
            <div className="modal fade" id="update-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update User</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={onUserUpdate} id="update-user">
                                <input
                                    onChange={onChange}
                                    value={formData.id}
                                    id="user-update-id"
                                    type="text"
                                    className="d-none"/>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="user-update-name">Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.name}
                                            id="user-update-name"
                                            type="text"
                                            error={errors.name}
                                            className={classnames("form-control", {
                                                invalid: errors.name
                                            })}/>
                                        <span className="text-danger">{errors.name}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="user-update-email">Email</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.email}
                                            error={errors.email}
                                            id="user-update-email"
                                            type="email"
                                            className={classnames("form-control", {
                                                invalid: errors.email
                                            })}
                                        />
                                        <span className="text-danger">{errors.email}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="user-update-password">Password</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            data-reset-input={true}
                                            autoComplete={''}
                                            onChange={onChange}
                                            error={errors.password}
                                            id="user-update-password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-user"
                                type="submit"
                                className="btn btn-primary">
                                Update User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserUpdateModal.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(UserUpdateModal);