import React from "react";
import PropTypes from 'prop-types';

export const FlashCircle = ({ className }) => (
    <svg 
        className={className} 
        width="12" 
        height="13" 
        viewBox="0 0 12 13" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="vuesax/bold/flash-circle">
            <g id="flash-circle">
                <path 
                    id="Vector" 
                    d="M5.98438 1.5C3.22438 1.5 0.984375 3.74 0.984375 6.5C0.984375 9.26 3.22438 11.5 5.98438 11.5C8.74438 11.5 10.9844 9.26 10.9844 6.5C10.9844 3.74 8.74938 1.5 5.98438 1.5ZM7.85938 6.675L5.99937 8.79L5.77938 9.04C5.47438 9.385 5.22437 9.295 5.22437 8.83V6.85H4.37437C3.98937 6.85 3.88437 6.615 4.13937 6.325L5.99937 4.21L6.21937 3.96C6.52437 3.615 6.77437 3.705 6.77437 4.17V6.15H7.62437C8.00937 6.15 8.11438 6.385 7.85938 6.675Z" 
                    fill="#234BFA"
                />
            </g>
        </g>
    </svg>
);

// Add PropTypes validation
FlashCircle.propTypes = {
    className: PropTypes.string
};