import React from "react";
import "./portfolioMobile.css";
import typeLogo2 from "../../assets/images/type-logo-2.png";
import sleekride from "../../assets/images/portfolio/sleekride.png";
import bred from "../../assets/images/portfolio/bred.png";
import leeosMerch from "../../assets/images/portfolio/leeos-merch.png";
import stayFresh from "../../assets/images/portfolio/stay-fresh.png";
import insureCert from "../../assets/images/portfolio/insurecert.png";

const PortfolioMobile = () => {
    return (
        <div className="portfolio-mobile">
            <div className="mobile-header">
                <img src={typeLogo2} alt="Type logo" className="logo" />
            </div>

            <div className="mobile-content">
                <div className="mobile-title-section">
                    <div className="mobile-badge">
                        <span>OUR WORK</span>
                    </div>
                    <h1>Our Portfolio(s)</h1>
                </div>

                <div className="mobile-divider" />

                <div className="mobile-projects">
                    <div className="featured-project">
                        <div className="featured-content">
                            <h2>ZeroCode</h2>
                            <p>Transforming the global EdTech industry for better future</p>
                            <div className="mobile-tags">
                                <span className="mobile-tag">Cloud</span>
                                <span className="mobile-tag">Website</span>
                            </div>
                        </div>
                        <div className="featured-label">FEATURED WORK</div>
                    </div>

                    <div className="mobile-project-card">
                        <img src={bred} alt="Bred" />
                        <div className="mobile-project-info">
                            <h2>Bred</h2>
                            <p>A stock-marketplace for athletic/luxury footwear with analytical tools</p>
                            <div className="mobile-tags">
                                <span className="mobile-tag">Mobile App</span>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-project-card">
                        <img src={leeosMerch} alt="Leeos Merch" />
                        <div className="mobile-project-info">
                            <h2>Leeos Merch</h2>
                            <p>Comprehensive merchandising solutions for all your needs</p>
                            <div className="mobile-tags">
                                <span className="mobile-tag">Website</span>
                                <span className="mobile-tag">E-commerce</span>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-project-card">
                        <img src={stayFresh} alt="Stay Fresh" />
                        <div className="mobile-project-info">
                            <h2>Stay Fresh</h2>
                            <p>Your go-to for premium sneakers, streetwear, and collectibles.</p>
                            <div className="mobile-tags">
                                <span className="mobile-tag">Website</span>
                                <span className="mobile-tag">E-commerce</span>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-project-card">
                        <img src={insureCert} alt="InsureCert" />
                        <div className="mobile-project-info">
                            <h2>InsureCert</h2>
                            <p>InsureCert provides a 360° view of insurance, seamlessly integrating underwriting, marketing, payments, and renewals through secure, custom portals.</p>
                            <div className="mobile-tags">
                                <span className="mobile-tag">Website</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioMobile;