import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const Sidebar = ({ logoutUser, auth }) => {
    const navigate = useNavigate();

    const onLogoutClick = e => {
        e.preventDefault();
        logoutUser(navigate);
    };

    return (
        <div className="border-right h-100" id="sidebar-wrapper">
            <div className="list-group list-group-flush">
                <Link to="/dashboard" className="list-group-item list-group-item-action">
                    Dashboard
                </Link>
                <Link to="/users" className="list-group-item list-group-item-action">
                    Users
                </Link>
                <Link to="/cms" className="list-group-item list-group-item-action">
                    cms
                </Link>
                <Link to="/events" className="list-group-item list-group-item-action">
                    Events
                </Link>
                <Link to="/blogs" className="list-group-item list-group-item-action">
                    Blogs
                </Link>
                <button 
                    className="list-group-item list-group-item-action" 
                    onClick={onLogoutClick}
                >
                    Logout <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);