import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCms } from "../../actions/cmsActions";
import { toast } from 'react-toastify';
import $ from 'jquery';

const CmsAddModal = ({ addCms, auth, errors: errorsProp }) => {
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        status: "1",
        alias: "",
        meta_title: "",
        meta_description: "",
        meta_keyword: "",
    });
    
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    useEffect(() => {
        if (auth?.user?.data?.message) {
            $('#add-cms-modal').modal('hide');
            toast(auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [auth?.user?.data?.message]);

    const handleChange = e => {
        setFormData(prev => ({
            ...prev,
            content: e.target.value
        }));
    };

    const onChange = e => {
        setFormData(prev => ({
            ...prev,
            [e.target.id]: e.target.value
        }));
    };

    const onCmsAdd = e => {
        e.preventDefault();
        const newCms = {
            title: formData.title,
            content: formData.content,
            status: formData.status,
            alias: formData.alias,
            meta_title: formData.meta_title,
            meta_description: formData.meta_description,
            meta_keyword: formData.meta_keyword
        };
        addCms(newCms, navigate);
    };

    const wrapperStyle = {
        border: '1px solid #969696',
    };

    const editorStyle = {
        height: '10rem',
        padding: '1rem'
    };

    return (
        <div>
            <div className="modal fade" id="add-cms-modal" data-reset="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Cms page</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={onCmsAdd} id="add-cms">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="title">Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.title}
                                            id="title"
                                            type="text"
                                            error={errors.title}
                                            className={classnames("form-control", {
                                                invalid: errors.title
                                            })}/>
                                        <span className="text-danger">{errors.title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="alias">Alias</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.alias}
                                            error={errors.alias}
                                            id="alias"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.alias
                                            })}
                                        />
                                        <span className="text-danger">{errors.alias}</span>
                                    </div>
                                </div>
                                
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Content</label>
                                    </div>
                                    <div className="col-md-9">
                                        <div style={{marginTop:'5%'}}> 
                                            <textarea 
                                                value={formData.content} 
                                                id="page-update-content" 
                                                onChange={handleChange}
                                                className="form-control" 
                                            />
                                        </div>
                                        <span className="text-danger">{errors.content}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="status">Status</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select 
                                            value={formData.status} 
                                            id="status" 
                                            onChange={onChange}
                                            className="form-control"
                                        >
                                            <option value="1">Enable</option>
                                            <option value="0">Disable</option>
                                        </select>
                                        <span className="text-danger">{errors.status}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="meta_title">Meta Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={onChange}
                                            value={formData.meta_title}
                                            error={errors.meta_title}
                                            id="meta_title"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_title
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="meta_description">Meta Description</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={onChange}
                                            value={formData.meta_description}
                                            id="meta_description"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_description
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_description}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="meta_keyword">Meta Keyword</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={onChange}
                                            value={formData.meta_keyword}
                                            id="meta_keyword"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.meta_keyword
                                            })}
                                        />
                                        <span className="text-danger">{errors.meta_keyword}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="add-cms"
                                type="submit"
                                className="btn btn-primary">
                                Add Cms page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CmsAddModal.propTypes = {
    addCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addCms }
)(CmsAddModal);