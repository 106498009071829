import React from 'react'
import { Link } from "react-router-dom";

function Footer() {
  return (
	<footer className="siteFooter">
		<div className="td-container">
			<div className="upperFooter">
				<div className="td-row">
					<div className="td-col-6">
						<div className="logoBox">
							<img src="images/blk-logo.png" alt="" />
						</div>
						<div className="content">
							<p>We provide high quality software development services, outsourcing, and professional consulting to companies around the world. 
								<br></br>
								<br></br> Proudly located in Vancouver BC, Canada.
							</p>
						</div>
					</div>
					{/* <div className="td-col-2">
						<div className="linkBox">
							<h3>Company</h3>
							<ul>
							<li><Link to={'company'}>About Us</Link></li>
							<li><a href="#">FAQ</a></li>
							<li><Link to={'blog'}>Blog</Link></li>
							</ul>
						</div>
					</div> */}
					<div className="td-col-2">
						<div className="linkBox">
							<h3>Customers</h3>
							<ul>
								{/* <li><Link to={'hiredeveloper'}>Hire a Developer</Link></li> */}
								{/* <li><Link to={'bookcall'}>Schedule a Call</Link></li> */}
								{/* <li><Link to={'howtohire'}>Contact Us</Link></li> */}
								<li><Link to='/contact'>Contact Us</Link></li>
								{/* <li><Link to={'howitwork'}>How it Works</Link></li> */}
								{/* <li><a href="#">Reviews</a></li> */}
							</ul>
						</div>
					</div>
					<div className="td-col-2">
						<div className="contactBox">
							<h3>Any Questions?</h3>
							<a href="mailto:hello@type-apps.com">hello@type-apps.com</a>
							<p>Let us know how we can help by getting in touch with one of our team members.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="bottomFooter">
				<div className="td-row alignCenter">
					<div className="td-col">
						<div className="copyright">
							<p>Copyright © 2025 Type Apps - All rights reserved.</p>
						</div>
					</div>
					<div className="td-col">
						<div className="links">
							<ul>
								<li><a href="#">Privacy Policy</a></li>
								<li><a href="#">Website Terms</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
  )
}

export default Footer