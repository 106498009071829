import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import { Link } from 'react-router-dom';
import './home.css';

// Updated Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation, Mousewheel, Keyboard } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Helmet } from 'react-helmet';

const Home = () => {
  const [meta, setMeta] = useState({});
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var page = 'home';
    let response = await fetch(`/api/page-meta?page=${page}`, {
      method: 'GET',
    });

    let result = await response.json();
    setMeta({ meta_title: result.meta_title, meta_description: result.meta_description, meta_keyword: result.meta_keyword });
  };

  return (
    <>
      <Helmet>
        <title>{meta?.meta_title}</title>
        <meta name="description" content={meta?.meta_description} />
        <meta name="keywords" content={meta?.meta_keyword} />
        {/* <!-- Brevo Conversations {literal} --> */}
        <script>
          {`
                    (function(d, w, c) {
                        w.BrevoConversationsID = '64615cd537644b462679fbe3';
                        w[c] = w[c] || function() {
                            (w[c].q = w[c].q || []).push(arguments);
                        };
                        var s = d.createElement('script');
                        s.async = true;
                        s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
                        if (d.head) d.head.appendChild(s);
                    })(document, window, 'BrevoConversations')
                `}
        </script>
        {/* <!-- /Brevo Conversations {/literal} --> */}
      </Helmet>
      <Header />
      <section className="heroSec">
        <div className="td-container">
          <div className="td-row alignCenter">
            <div className="td-col-7">
              <div className="contentBox">
                <div className="mainContent">
                  <h2>
                    Build Your Remote <span>Development Team.</span>
                  </h2>
                  <p>Fast, Simple & Affordable.</p>
                  <Link to={'/contact'} className="btn blueBtn">
                    Hire a Developer
                  </Link>
                </div>
                <div className="bottomContent">
                  <div className="td-row">
                    <div className="td-col">
                      <div className="content">
                        <h3>2 to 5 Days</h3>
                        <p>Is the average amount of time to hire a developer.</p>
                      </div>
                    </div>
                    <div className="td-col">
                      <div className="content">
                        <h3>92%</h3>
                        <p>Engagement success rate for hired developers.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="td-col-5">
              <div className="imgBox">
                <img className="mainImg" src="./images/Type-Apps-Vancouver.png" alt="Type Apps Vancouver" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="logoSection secPadding">
        <div className="td-container">
          <h2>Trusted By Leading Companies Like</h2>
          <div className="td-row">
            <div className="td-col">
              <div className="logoImg">
                <img src="./images/insurecert-logo.png" alt="insurecert-logo" />
              </div>
            </div>
            <div className="td-col">
              <div className="logoImg">
                <img src="./images/sleekride-logo.png" alt="sleekride-logo" />
              </div>
            </div>
            <div className="td-col">
              <div className="logoImg">
                <img src="./images/bred-logo.png" alt="bred-logo" />
              </div>
            </div>
            <div className="td-col">
              <div className="logoImg">
                <img src="./images/leeos-merch-logo.png" alt="leeos-merch-logo" />
              </div>
            </div>
            <div className="td-col">
              <div className="logoImg">
                <img src="./images/stayfresh-logo.png" alt="stay-fresh-logo" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="coreFeature secPadding">
        <div className="td-container">
          <div className="td-row">
            <div className="td-col-12 mb-80">
              <div className="content">
                <span className="tag">
                  <img src="./images/core-icon-1.png" alt="Core Features" />
                  Core Features
                </span>
                <h2 className="heading">Hire fast, launch faster</h2>
                <p className="para">
                  Hire the top 10% of experienced developers faster, better, and easier with us.
                  <br /> We carefully vet all our developers to ensure only the best end up working with our clients.
                </p>
                <Link to="/contact" className="blackBtn">
                  Learn More
                </Link>
              </div>
            </div>
            <div className="td-col-3">
              <div className="boxarea">
                <img className="mb-30" src="./images/core-icon-2.png" alt="Project Management" />
                <h2>Project Management</h2>
                <p> Regardless of size, every team is assigned a Technical Lead to ensure the Developers work is aligned with our clients&apos; vision.</p>
              </div>
            </div>
            <div className="td-col-3">
              <div className="boxarea">
                <img className="mb-30" src="./images/core-icon-3.png" alt="Daily Reports" />
                <h2>Daily Reports</h2>
                <p>Keep up with development progress by viewing daily updates and chatting with the team anytime.</p>
              </div>
            </div>
            <div className="td-col-3">
              <div className="boxarea">
                <img className="mb-30" src="./images/core-icon-4.png" alt="Flexibility" />
                <h2>Flexibility</h2>
                <p>Scale up or down with your remote Development team according to your requirements. No commitments, cancel anytime.</p>
              </div>
            </div>
            <div className="td-col-3">
              <div className="boxarea">
                <img className="mb-30" src="./images/core-icon-5.png" alt="Save Over 50%" />
                <h2>Save Over 50%</h2>
                <p>Our clients report over a 50% reduction in hiring expenses when working with us.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourProcess secPadding">
        <div className="td-container">
          <div className="td-row">
            <div className="td-col-12 mb-80">
              <div className="content text-center">
                <span className="tag">
                  <img src="./images/core-icon-1.png" alt="Our Process" />
                  Our Process
                </span>
                <h2 className="heading">A convenient process of Hiring</h2>
                <p className="para">
                  Tell us the skills and requirements you need and we&apos;ll find the
                  <br />
                  best developer for you in just a few days.
                </p>
              </div>
            </div>

            <div className="td-col-4">
              <div className="boxarea top-arrow">
                <img className="mb-90" src="./images/no-1.png" alt="Step 1" />
                <h2>Talk to our Expert</h2>
                <p>An expert on our team will work with you to understand your goals, technical needs, and team dynamics.</p>
              </div>
            </div>
            <div className="td-col-4">
              <div className="boxarea bottom-arrow">
                <img className="mb-90" src="./images/no-2.png" alt="Step 2" />
                <h2>Hand-selected Developer</h2>
                <p>Within days, we&apos;ll introduce you to the right developer for your project needs. Average time to match is 2-5 days.</p>
              </div>
            </div>
            <div className="td-col-4">
              <div className="boxarea">
                <img className="mb-90" src="./images/no-3.png" alt="Step 3" />
                <h2>Start Coding</h2>
                <p>Once the scope of work is confirmed, your new remote developer will start on your project right away.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sliderSection secPadding">
        <div className="td-container">
          <div className="td-row">
            <div className="td-col-12 mb-80">
              <div className="content text-center">
                <Swiper
                  modules={[EffectCoverflow, Pagination, Navigation, Mousewheel, Keyboard]}
                  effect="coverflow"
                  grabCursor={false}
                  centeredSlides={true}
                  slidesPerView="auto"
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  pagination={false}
                  navigation={true}
                  mousewheel={true}
                  keyboard={true}
                  className="mySwiper">
                  {[...Array(8)].map((_, index) => (
                    <SwiperSlide key={index}>
                      <div className="td-row content-area">
                        <div className="td-col-6 img-part">
                          <img src="./images/slider-1.png" alt="Slider" />
                        </div>
                        <div className="td-col-6 text-part">
                          <img className="qoute" src="./images/qoute.png" alt="Quote" />
                          <h2 className="heading">
                            Type has been an amazing website for me and my company to hire talented remote developers. I just cannot believe how much
                            my business has been growing. They are insane.
                          </h2>
                          <p className="name">Samuel Jackson</p>
                          <small>Lead Developer</small>
                          <img className="client-logo" src="./images/bridg-logo.png" alt="Bridg Logo" />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hire secPadding">
        <div className="td-container background">
          <div className="td-row">
            <div className="td-col-12">
              <div className="content text-left">
                <h2 className="heading">Hire your Developers Now</h2>
                <p className="para">
                  Tell us the skills and requirements you need and we&apos;ll find the best
                  <br /> developer for you in only just a few days.
                  <Link to={'/contact'} className="whiteBtn">
                    Learn More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;