import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { isMobile } from 'react-device-detect';
import PortfolioDesktop from "../portfolio/portfolioDesktop";
import PortfolioMobile from "../portfolio/portfolioMobile";

function Portfolio() {
    return (
        <div className="portfolio">
            <Header />
            {isMobile ? <PortfolioMobile /> : <PortfolioDesktop />}
            <Footer />
        </div>
    );
}

export default Portfolio;