import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ children, auth }) => {
    const location = useLocation();

    // If not authenticated, redirect to login with the return path
    if (!auth.isAuthenticated) {
        return (
            <Navigate 
                to="/login" 
                replace 
                state={{ 
                    from: location.pathname,
                    message: "Please log in to access this page" 
                }} 
            />
        );
    }

    // If authenticated, render children components
    return (
        <div className="dashboard-container">
            {/* You can add layout components here if needed */}
            {children}
        </div>
    );
};

PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);