import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import './blog.css';
import PropTypes from 'prop-types';

const Detail = (props) => {
    // let location = useLocation();
    let search = props.location.search;

    const id = new URLSearchParams(search).get('id');

    // const id = '6294d570753aa13f70eca172'
    const [blog, setBlog] = useState([]);
    const [recentblogs, setRecentBlog] = useState([]);

    useEffect(() => {
        getData(id);
    }, [id]); // Added id to dependency array

    const getData = async (id) => {
        let response = await fetch(`/api/blog-detail?id=${id}`, {
            method: "GET"
        });
        
        let result = await response.json();
        setBlog(result.blog[0]);
        setRecentBlog(result.recent);
    };

    return (
        <>
            <Header />
            <section className="blogSec secPadding">
                <div className="td-container">
                    {/* <div className="mainContent text-center">
                        <div className="tag"><span><img src="../../images/blog-icon.svg" alt="" /> Blogs</span></div>
                        <h2>Read some amazing blog writen by us</h2>
                        <p>Discover resources, guides and articles that can help you build unique solutions. Visit our learning centre for heaps of content.</p>
                    </div> */}
                    <div className="blogBoxes">
                        <div className="td-row">
                            <div className="td-col-7">
                                <div className="bigBlog">
                                    <div className="blogImg">
                                        <img src={'/blog_image/' + blog?.image} alt={blog?.title || "Blog post"} />
                                    </div>
                                    <div className="blogContent">
                                        <div className="metaData"><span className="tag">{blog?.categoryname}</span> <span className="date">Feb 04</span></div>
                                        <h3>{blog?.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                                    </div>
                                </div>
                            </div>
                            <div className="td-col-5">
                                {recentblogs.map((data, index) => (
                                    <div className="smallBlog" key={index}>
                                        <div className="blogImg">
                                            <Link to={`/detail?id=${data._id}`}>
                                                <img src={"/blog_image/" + data.image} alt={data.title || "Recent blog post"} />
                                            </Link>
                                        </div>
                                        <div className="blogContent">
                                            <div className="metaData"><span className="tag">{data.categoryname}</span> <span className="date">9 Hours Ago</span></div>
                                            <Link to={`/detail?id=${data._id}`}><h3 className='smallTitle'>{data.title}</h3></Link>
                                            <p className='smallDesc' dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

// Add PropTypes validation
Detail.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired
    }).isRequired
};

export default Detail;