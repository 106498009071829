import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

const UserAddModal = ({ addUser, auth, errors: errorsProp }) => {
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        password2: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (errorsProp) {
            setErrors(errorsProp);
        }
    }, [errorsProp]);

    useEffect(() => {
        if (auth?.user?.data?.message) {
            $('#add-user-modal').modal('hide');
            toast(auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [auth?.user?.data?.message]);

    const onChange = e => {
        setFormData(prev => ({
            ...prev,
            [e.target.id]: e.target.value
        }));
    };

    const onUserAdd = e => {
        e.preventDefault();
        const newUser = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            password2: formData.password2
        };
        addUser(newUser, navigate);
    };

    return (
        <div>
            <div className="modal fade" id="add-user-modal" data-reset="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add User</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={onUserAdd} id="add-user">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.name}
                                            id="name"
                                            type="text"
                                            error={errors.name}
                                            className={classnames("form-control", {
                                                invalid: errors.name
                                            })}/>
                                        <span className="text-danger">{errors.name}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={onChange}
                                            value={formData.email}
                                            error={errors.email}
                                            id="email"
                                            type="email"
                                            className={classnames("form-control", {
                                                invalid: errors.email
                                            })}
                                        />
                                        <span className="text-danger">{errors.email}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={onChange}
                                            value={formData.password}
                                            error={errors.password}
                                            id="password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="password2">Confirm Password</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={onChange}
                                            value={formData.password2}
                                            id="password2"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password2
                                            })}
                                        />
                                        <span className="text-danger">{errors.password2}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="add-user"
                                type="submit"
                                className="btn btn-primary">
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserAddModal.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(UserAddModal);