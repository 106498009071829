import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import BlogAddModal from "../partials/BlogAddModal";
import BlogUpdateModal from "../partials/BlogUpdateModal";
import { toast, ToastContainer} from "react-toastify";

const initialState = {
    isUpdate   : false,
    isAdded    : false
}
class Blogs extends Component {

    constructor(props) {
        super(props);
        
        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "title",
                text: "Title",
                className: "title",
                align: "left",
                sortable: true,
            },
            {
                key: "categoryname",
                text: "Category",
                className: "category",
                align: "left",
                sortable: true
            },
            {
                key: "description",
                text: "Description",
                className: "description",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <div dangerouslySetInnerHTML={{__html:record.description}} ></div>
                        </Fragment>
                    );
                }
            },
            {
                key: "image",
                text: "Image",
                className: "image",
                align: "left",
                sortable: true,
                cell: record => {
                    
                    return (
                        
                        <Fragment>
                            <img style={{height: "100px"}} src={'/blog_image/'+record.image} alt={record.title || "Blog image"} />
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-blog-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            
        };

        this.state = {
            isAdded : false,
            isUpdate : false,       
            ...initialState
        }
        
     

        this.state = {
            currentRecord: {
                id: '',
                title: '',
                category: '',
                description: '',
                image: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    // Update to UNSAFE_ prefix for deprecated lifecycle method
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .get("/api/blog-data")
            .then(res => {
                this.setState({ records: res.data.blog,category: res.data.category})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
        this.setState({ isUpdate: false});
    }

    deleteRecord(record) {
        axios
            .post("/api/blog-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    handelModel(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        console.log('isUpdate',this.state.isUpdate);
        return (
            <div>    
                <ToastContainer />   
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <BlogAddModal isAdded={ this.state.isAdded } category = {this.state.category} />
                    <BlogUpdateModal update={ this.state.isUpdate } category = {this.state.category} record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-blog-modal"><FontAwesomeIcon icon={faPlus}/> Add BLog</button>
                            <h1 className="mt-2 text-primary">Blog List</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    
                </div>
                
            </div>
            
        );
    }

}

Blogs.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Blogs);